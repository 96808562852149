<template>
	<div class="page">
		<van-nav-bar title="주문 확인" class="nav-bar">
			<template #left>
				<van-icon name="arrow-left" color="#fff" @click="back()"/>
			</template>
		</van-nav-bar>
		<div class="tableLine"><span class="midText"></span></div>
		<div class="box">
			<!-- <van-image width="100%"  height="100%" v-for="(v, k) in xuanfeidata.img_url" :key="k"  :src="v"  /> -->
			<!-- <div class="content_title">
				{{ this.xuanfeidata.title }}
			</div> -->
			<div class="liucheng_title">서비스 사항</div>
			<div class="pic_table">
				<div class="pic_left">
					<van-image width="100%" radius="10" height="80%" v-for="(v, k) in xuanfeidata.img_url" :key="k"  :src="v"  />
				</div>
				<div class="right_tablee">
					<li><van-image width="19%" height="80%" src="/img/rz.png"/>&nbsp;&nbsp;{{ this.xuanfeidata.title }}</li>
					<li>{{ this.xuanfeidata.xuanfei_name }}</li>
				</div>
			</div>
			
			<hr size="1" style="width: 90%;margin: auto;">
			<div class="liucheng_title">친절한 팁</div>
			<div class="content_title" style="font-size: .77rem;line-height: 1.4rem;margin-top: -1%;">
				【아직 활성화하지 않았습니다.】，담당자에게 연락하여 데이트 예약을 잡아주세요.。
			</div>
			<hr size="1" style="width: 90%;margin: auto;">
			<div class="liucheng_title">방문확인</div>
			<div class="content_title" style="font-size: .77rem;line-height: 1.4rem;margin-top: -1%;">
				개인 정보에 문의주세요.
			</div>
			<van-image width="86%"  height="100%" style="margin:auto;margin-top:5%;" src="/img/ac75625.png" />
			
		</div>

		
	</div>
</template>

<script>
export default {
	data() {
		return {
			xuanfeidata: {
				xuanfei_name: '',
				vo_title: '',
				img_url: []
			},
			show: false,
			value: ""
		};
	},
	methods: {
		back() {
			this.$router.back();
		},
		getxuanfeidata() {
			this.$http({
				method: 'get',
				url: 'xuanfeidata1',
				data: { id: this.$route.query.id }
			}).then(res => {
				this.xuanfeidata = res.data;
			});
		},
		yuyue() {
			this.$toast('담당자나 개인 정보에 문의하세요.');
		}
	},
	created() {
		this.getxuanfeidata();
	}
};
</script>
<style >
.pic_table{width: 90%;height: 10vh;margin: auto;}
.pic_left{width:20%;float:left;height: 10vh;}
.right_tablee{width:75%;float:right;height: 10vh;}
.right_tablee li{width: 100%;float: left;line-height: 4vh;color: #fff;text-align: left;list-style: none;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;font-size: .9rem;}
.tableLine {
	position: relative;
	margin: 0 auto;
	height: 1px;
	background-color: #766350;
  }
.midText {
	position: absolute;
	left: 50%;
	background-color: #ffffff;
	padding: 0 15px;
	transform: translateX(-50%) translateY(-50%);
  }
body {
    background: #282828;
}
.van-cell {
	font-size: 3.867vw;
}

.container {
	display: inline-block;
}

.box {
	width: 100%;
	margin: 0 auto;
	text-align: center;
	padding-bottom: 2.25rem;
}

.name {
	font-size: 1.125rem;
	color: #ddb285;
}
.timibbs_title{
    font-size: 1.125rem;
    color: #ddb285;
    border-radius: 6px;
    background: #585049;
    width: 60%;
    display: inline-block;
    padding: 6px;
    font-weight: 600;
}
.title {
	font-size: 0.625rem;
}
.button {
	height: 2.3125rem;
	line-height: 2.3125rem;
	border-radius: 0.5625rem;
	background: #ceb585;
	text-align: center;
	font-size: 1rem;
	font-family: PingFang SC;
	font-weight: bold;
	color: #000000;
	width: 90%;
	border: 0;
}
.picend{
    height: 2.75rem;
    background: #fbc893 url(/img/ygn.f420236.png) no-repeat;
    background-size: 5.3125rem 2.3125rem;
    background-position: 0.875rem center;
	margin-top: -1%;
}
.content_table{
	width: 90%;height: auto;margin:auto;display: table;text-align: left;margin-top: 6%;
}
.content_title{
	width: 90%;height: auto;margin:auto;display: table;text-align: left;margin-top: 6%;color:#fff;margin-bottom:4%;
}
.content_table span{
	display: inline-block;
	width: 3.8rem;
	line-height: 2rem;
	border: 0.0625rem solid #ffcc99;
	text-align: center;
	font-size: 0.75rem;
	font-family: PingFang SC;
	color: #FFCC99;
	margin-right: 0.3125rem
}
.liucheng_title{
	font-size: .9rem;
	font-family: PingFang SC;
	font-weight: 500;
	color: #ECCA6F;
	padding: 1.0625rem 1.25rem;
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAZCAYAAAAiwE4nAAACxUlEQVRIia2WW4iNURTHf3N4wvfgAYNRhxL5xmXcH1yTNKIpDx6UkAlPzquM2zCYKdL3JMmUSbnlDXnQ5BqJUcwpCk2NS24jc5qhQbRm/l/z9e1zxjkz83/Zt7X2f6+111p7F7U37yQLlgPLgMXAH+AucAe4FRf1/IBMOpUEVgNLgPFAM/AIuOT5QUdUPk44D6gHSt0zdKMJ2A48jsydBiodyR7YYas8P6jLRrgNOKX+FeAC8EbjicBGoELjCllgFk+R3DHgGdAOjAYWAQckf9Xzg7VRwqURd5ngfeesPVgF3IjN1Xl+sMuRBHP1GHOrXH3e84MNIeEHoBhYATQCw4BO6ZUBNcAe4CkwB7gG2Ga7PT846jC5xK+BScDcBLBOZA0i2wJ8B9ZIfpQCIqnxE2Cs3PZfMmG92uNm4XWgHBgO/AK6tDgVeKm+rXU42yhK80EmnWoFShKRiOzUXRr2R8jIRVYg7pl4Qqf/K91itU2DQBDHz5DwvUWrFh+qzVoNBohkSNiofSwdXikSV1oEDjKhVa0fRnhCExfVLgC+AdWOSj+RSacOAUOAWiNsAc4B44CTilQzf/IgkS1UDv8OCVHuGfEO4LDKU4ujXTjZbOCBhuWeH3SFhMY+H2jV3eWb0DmRSadKVSQMmzw/uImCJsRnYBbwCbDaeDDXZnmQWfl7ruFmzw8awrVETLZNpJYqe1UACiWbHnm+Kj0/OBtdjxOiQm5KX/W87HMkciCTTpXpiTJs9fzgTFwyGyGydKbIq2Vtn8ikUyWRCmVurM8mP9SZ6cU7wAde6D7t9T7iSPXC7v42cDnuxihy/WmiKFG02XNUlYXUojvQV6LR0Y4hl0ujeCtL25SjVbH1EYAl9wRHMwv6cmkUX4AZCoga5W33x0hWFTkaA7AwhN3pNN1VLWD1Md8D94vQ8FF/GnvbrD6OLEgb+Ad3n7rHyr3uYgAAAABJRU5ErkJggg==) no-repeat;
	background-size: .99rem .99rem;
	background-position: 0 center;
	text-align: left;
	width: 90%;
	margin: auto;
}
.c_list_li{width: 25%;float: left;height: auto;}
.img{height: 2.375rem;background-size: 2.375rem;background-position: center;background-repeat: no-repeat;background-image: url(/img/i1.png);}
.img2{height: 2.375rem;background-size: 2.375rem;background-position: center;background-repeat: no-repeat;background-image: url(/img/i2.png);}
.img3{height: 2.375rem;background-size: 2.375rem;background-position: center;background-repeat: no-repeat;background-image: url(/img/i3.png);}
.img4{height: 2.375rem;background-size: 2.375rem;background-position: center;background-repeat: no-repeat;background-image: url(/img/i4.png);}
</style>
