<template>
	<div class="page">
		<van-nav-bar title="세부" class="nav-bar">
			<template #left>
				<van-icon name="arrow-left" color="#fff" @click="back()"/>
			</template>
		</van-nav-bar>
		<div class="tableLine"><span class="midText"></span></div>
		<div class="box">
			<!-- <van-image width="98%" fit="contain" height="100%" v-for="(v, k) in xuanfeidata.img_url" :key="k" :src="v" /> -->
			<div class="banner">
			<swiper class="banner_swiper" :options="bannerSwiperOption">
			<swiper-slide v-for="(v, k) in imglist" :key="k">
			<van-image class="banner_img" :round="true" :src="v">
			<template v-slot:loading>
			<van-loading type="circular" />
			</template>
			</van-image>
			</swiper-slide>
			</swiper>
			</div>
			<div style="width: 100%;height: 1rem;margin: auto;"></div>
			<div class="picend"></div>
			<div class="content_table">
				<span style="width: 5.5rem;">2회 섹스</span>
				<span style="width: 5.5rem;">긴 다리</span>
				<span style="width: 5.5rem;">잘생겼어</span>
			</div>
			<div class="content_title">
				{{ this.xuanfeidata.title }}
			</div>
			<div class="liucheng_title">아가씨 초이스</div>
			<div class="c_ico_list">
				<div  class="c_list_li">
					<div class="img"></div>
					<p >온라인으로 선택</p>
				</div>
				<div  class="c_list_li">
					<div class="img2"></div>
					<p >주문하고 약속을 잡으세요</p>
				</div>
				<div  class="c_list_li">
					<div class="img3"></div>
					<p >방문 서비스</p>
				</div>
				<div  class="c_list_li">
					<div class="img4"></div>
					<p >평가 서비스</p>
				</div>
			</div>
			<div class="liucheng_title">아가씨 프로필</div>
			<div class="content_title" style="font-size: .9rem;line-height: 1.4rem;margin-top: -1%;">
				{{ this.xuanfeidata.xuanfei_name }}
			</div>
			<div class="liucheng_title">아가씨 세부 사항</div>
			<div class="liucheng_title">주의사항</div>
			<p class="title">{{ this.xuanfeidata.vo_title }}</p>
			<van-button round="true" @click="addgo()" class="button"
				>연결하려면 클릭하세요.</van-button>
		</div>

		<!-- <van-popup v-model="show">
			<div style="display: flex; flex-direction: column;align-items: center;padding: 10px 30px;">
				<h4 style="margin: 10px 0;">直播打榜</h4>
				<van-field v-model="value" placeholder="请输入主播编号" />
				<van-button round="true" class="button"	@click="$router.push({ path: '/Order' })">确定</van-button>
			</div>
		</van-popup> -->
	</div>
</template>

<script>
export default {
	data() {
		return {
			xuanfeidata: {
				xuanfei_name: '',
				vo_title: '',
				img_url:''
			},
			show: false,
			value: "",
			imglist:'',
			banners: [{}],
			bannerSwiperOption: {
			effect: 'coverflow',
			grabCursor: true,
			centeredSlides: true,
			slidesPerView: 'auto',
			speed: 800,
			autoplay: true,
			coverflowEffect: {
			rotate: 50,
			stretch: 10,
			depth: 100,
			modifier: 1,
			slideShadows: true
			}
			},
		};
	},
	methods: {
		addgo() {
			if (!localStorage.getItem('token')) {
			this.$router.push({ path: '/Login' });
			} else {
			this.$router.push({ path: `/order?id=`+this.$route.query.id });
			}
		},
		back() {
			this.$router.back();
		},
		getxuanfeidata() {
			this.$http({
				method: 'get',
				url: 'xuanfeidata',
				data: { id: this.$route.query.id }
			}).then(res => {
				this.xuanfeidata = res.data;
				this.imglist = res.data.img_url;
			});
		},
		yuyue() {
			this.$toast('담당자나 개인 정보에 문의하세요.');
		}
	},
	created() {
		this.getxuanfeidata();
	}
};
</script>
<style >
.tableLine {position: relative;
	margin: 0 auto;
	height: 1px;
	background-color: #766350;
  }
.midText {
	position: absolute;
	left: 50%;
	background-color: #ffffff;
	padding: 0 15px;
	transform: translateX(-50%) translateY(-50%);
  }
body {
    background: #282828;
}
.van-cell {
	font-size: 3.867vw;
}

.container {
	display: inline-block;
}

.box {
	width: 100%;
	margin: 0 auto;
	text-align: center;
	padding-bottom: 2.25rem;
}

.name {
	font-size: 1.125rem;
	color: #ddb285;
}
.timibbs_title{
    font-size: 1.125rem;
    color: #ddb285;
    border-radius: 6px;
    background: #585049;
    width: 60%;
    display: inline-block;
    padding: 6px;
    font-weight: 600;
}
.title {
	font-size: 0.625rem;
}

.button {

	height: 2.3125rem;
	line-height: 2.3125rem;
	border-radius: 0.5625rem;
	background: #ceb585;
	text-align: center;
	font-size: 1rem;
	font-family: PingFang SC;
	font-weight: bold;
	color: #000000;
	width: 90%;
	border: 0;
}
.picend{
    height: 2.75rem;
    background: #fbc893 url(/img/ygn.f420236.png) no-repeat;
    background-size: 5.3125rem 2.3125rem;
    background-position: 0.875rem center;
	margin-top: 3%;
}
.content_table{
	width: 90%;height: auto;margin:auto;display: table;text-align: left;margin-top: 6%;
}
.content_title{
	width: 90%;height: auto;margin:auto;display: table;text-align: left;margin-top: 6%;color:#fff;
}
.content_table span{
	display: inline-block;
	width: 5.5rem;
	line-height: 2rem;
	border: 0.0625rem solid #ffcc99;
	text-align: center;
	font-size: 0.75rem;
	font-family: PingFang SC;
	color: #FFCC99;
	margin-right: 0.3125rem
}
.liucheng_title{
	font-size: .9rem;
	font-family: PingFang SC;
	font-weight: 500;
	color: #ECCA6F;
	padding: 1.0625rem 1.25rem;
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAZCAYAAAAiwE4nAAACxUlEQVRIia2WW4iNURTHf3N4wvfgAYNRhxL5xmXcH1yTNKIpDx6UkAlPzquM2zCYKdL3JMmUSbnlDXnQ5BqJUcwpCk2NS24jc5qhQbRm/l/z9e1zxjkz83/Zt7X2f6+111p7F7U37yQLlgPLgMXAH+AucAe4FRf1/IBMOpUEVgNLgPFAM/AIuOT5QUdUPk44D6gHSt0zdKMJ2A48jsydBiodyR7YYas8P6jLRrgNOKX+FeAC8EbjicBGoELjCllgFk+R3DHgGdAOjAYWAQckf9Xzg7VRwqURd5ngfeesPVgF3IjN1Xl+sMuRBHP1GHOrXH3e84MNIeEHoBhYATQCw4BO6ZUBNcAe4CkwB7gG2Ga7PT846jC5xK+BScDcBLBOZA0i2wJ8B9ZIfpQCIqnxE2Cs3PZfMmG92uNm4XWgHBgO/AK6tDgVeKm+rXU42yhK80EmnWoFShKRiOzUXRr2R8jIRVYg7pl4Qqf/K91itU2DQBDHz5DwvUWrFh+qzVoNBohkSNiofSwdXikSV1oEDjKhVa0fRnhCExfVLgC+AdWOSj+RSacOAUOAWiNsAc4B44CTilQzf/IgkS1UDv8OCVHuGfEO4LDKU4ujXTjZbOCBhuWeH3SFhMY+H2jV3eWb0DmRSadKVSQMmzw/uImCJsRnYBbwCbDaeDDXZnmQWfl7ruFmzw8awrVETLZNpJYqe1UACiWbHnm+Kj0/OBtdjxOiQm5KX/W87HMkciCTTpXpiTJs9fzgTFwyGyGydKbIq2Vtn8ikUyWRCmVurM8mP9SZ6cU7wAde6D7t9T7iSPXC7v42cDnuxihy/WmiKFG02XNUlYXUojvQV6LR0Y4hl0ujeCtL25SjVbH1EYAl9wRHMwv6cmkUX4AZCoga5W33x0hWFTkaA7AwhN3pNN1VLWD1Md8D94vQ8FF/GnvbrD6OLEgb+Ad3n7rHyr3uYgAAAABJRU5ErkJggg==) no-repeat;
	background-size: .99rem .99rem;
	background-position: 0 center;
	text-align: left;
	width: 90%;
	margin: auto;
}
.c_ico_list{
	width: 90%;height: auto;margin:auto;display: table;
	font-size: 0.75rem;
	text-align: center;
	color: #ECCA6F;
	margin-top: 0.3125rem;
}
.c_list_li{width: 25%;float: left;height: auto;}
.img{height: 2.375rem;background-size: 2.375rem;background-position: center;background-repeat: no-repeat;background-image: url(/img/i1.png);}
.img2{height: 2.375rem;background-size: 2.375rem;background-position: center;background-repeat: no-repeat;background-image: url(/img/i2.png);}
.img3{height: 2.375rem;background-size: 2.375rem;background-position: center;background-repeat: no-repeat;background-image: url(/img/i3.png);}
.img4{height: 2.375rem;background-size: 2.375rem;background-position: center;background-repeat: no-repeat;background-image: url(/img/i4.png);}

.banner {
  width: 100%;

}

.banner_swiper {
  height: 100%;
  width: 100%;

  .swiper-slide {
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 620px;
    height: 700px;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    background-color: #ffffff;
    background-position: center;
    background-size: cover;
    color: #ffffff;
  }
}

::v-deep .swiper-container-3d .swiper-slide-shadow-left {
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
}

::v-deep .swiper-container-3d .swiper-slide-shadow-right {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
}

.banner_img {
  border-radius: 10px;
  width: 100%;
  height: 50vh;
}


</style>
