<template>
  <div class="container page" style="height: calc(100% - 200px)">
    <div class="header">
      <van-nav-bar title="메시지 관리" class="nav-bar">
        <template #left>
          <van-icon name="arrow-left" color="#fff" @click="back()"/>
        </template>
      </van-nav-bar>
    </div>
	
	<div class="content" style="margin-top:3%;height: auto;display: table;">
		<van-pull-refresh v-model="isLoading" @refresh="onRefresh">
			<div class="listItem">
			<div class="listTitle">
				{{this.notice.create_time}}
			</div>
			<!-- <div class="listContent html"><p style="font-size: .9rem;line-height: 3vh;color: #616161;">{{ this.notice.text}}<br></p></div> -->
			<div v-html="this.notice.text" style="margin-bottom: 5vh;color: #8d8d8d;"></div>

		</div>
		</van-pull-refresh>
	</div>
  
  </div>

</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      notice:{},
      loading: false,
      finished: false,
    };
  },
  methods: {
    back(){
      return window.history.back();
    },
    getNoticeList(){
      this.$http({
        method: 'get',
        url: 'sys_get_notice_user',
		data: { id: this.$route.query.id }
      }).then(res=>{
        console.log(res);
        this.notice = res.data
      })
    },
    onRefresh() {
      setTimeout(() => {
        this.$toast("새로고침 성공");
        this.isLoading = false;
        this.getNoticeList();
      }, 500);
    },
  },
  created() {
    this.getNoticeList();
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
::v-deep .van-pull-refresh__track .van-pull-refresh__head *{
  color: #000000;
  font-size: 35px;
}

::v-deep .van-loading__text {
  color: #000000;
  font-size: 35px;
}
.nav {
  display: flex;
  padding: 15px;

  .nav-item {
    font-size: 3.5vw;
    padding: 1.333vw 2vw;
    border-radius: 6.667vw;
    margin-right: 1.867vw;
    /* background: linear-gradient(90deg, #5b606c, #2b2b2b); */
    color: #fff;
    margin-bottom: 1.333vw;
	width: 33%;
	text-align: center;
    &.active {
      background: linear-gradient(#fde3ca, #e7b486);
      color: #c96708;
    }
  }
}
.nav-bar {
  background: linear-gradient(120deg, #212121, #313131);
}
.container .content {
  height: calc(100% - 20px);
  overflow: auto;
}
.container .content .listItem{
  margin-bottom: 20px;
  padding: 20px 20px 0;
  position: relative;
  color: #000;
  background-color: #fff;
  width: 95%;
  margin:auto;
  margin-bottom: 2vh;
border-radius: 0.5rem;
display: table;
}
.container .content .listItem .listTitle{
  font-size: 1rem;
  color:#999;
  height: 3vh;
}
.container .content .listItem .listContent{
  border-bottom: 2px solid #f2f2f5;
  padding: 5px 0;
  font-size: 25px;
}
.container .content .listItem .listTime{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 70px;
}
.container .content .listItem .listTime .listTimeText {
  color: #656566;
  font-size: 30px;
}




</style>
