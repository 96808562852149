<template>
  <div class="container page" style="height: calc(100% - 200px)">
    <div class="header">
      <van-nav-bar title="메시지 관리" class="nav-bar">
        <template #left>
          <van-icon name="arrow-left" color="#fff" @click="back()"/>
        </template>
      </van-nav-bar>
    </div>
	<div class="nav">
		<p class="nav-item" :class="{ 'active': active == 2 }" @click="active = 2">사이트 메시지</p>
		<p class="nav-item" :class="{ 'active': active == 1 }" @click="active = 1">시스템 메시지</p>
		<p class="nav-item" :class="{ 'active': active == 0 }" @click="active = 0">홈페이지 공지</p>
	</div>
	<div class="content" v-if="active == 2" style="margin-top:3%">
		<van-pull-refresh v-model="isLoading" @refresh="onRefresh">
			<div class="listItem" v-for="(v,key) in notice2" :key="key">
			<div class="listTitle">
			{{ v.create_time }}
				<img  @click="showAlert(2,v.id)" src="/img/del.png" style="position: absolute;width: 6%;right: 6%;margin-top: 2%;">
				</div>
				<!-- <div class="listContent html"><p>{{ v.text }}<br></p></div> -->
			<div @click="addgo(v.id)" class="listTime" style="text-align: left;width: 100%;float: left;display: contents;line-height: 5vh;color: #939393">확인하다</div>
		</div>
		</van-pull-refresh>
	</div>
	<div class="content" v-if="active == 1" style="margin-top:3%">
		<van-pull-refresh v-model="isLoading" @refresh="onRefresh">
			<div class="listItem" v-for="(v,key) in notice1" :key="key">
			<div class="listTitle">
			{{ v.create_time }}
				<img @click="showAlert(1,v.id)" src="/img/del.png" style="position: absolute;width: 6%;right: 6%;margin-top: 2%;">
				</div>
				<!-- <div class="listContent html"><p>{{ v.text }}<br></p></div> -->
			<div @click="addgo(v.id)" class="listTime" style="text-align: left;width: 100%;float: left;display: contents;line-height: 5vh;color: #939393">확인하다</div>
		</div>
		</van-pull-refresh>
	</div>
   <div class="content" v-if="active == 0" style="margin-top:3%">
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
          <div class="listItem" v-for="(v,key) in notice" :key="key">
            <div class="listTitle">
			{{ v.create_time }}
			<img @click="showAlert(0,v.id)" src="/img/del.png" style="position: absolute;width: 6%;right: 6%;margin-top: 2%;">
			</div>
            <!-- <div class="listContent html"><p>{{ v.text }}<br></p></div> -->

            <div @click="addgo(v.id)" class="listTime" style="text-align: left;width: 100%;float: left;display: contents;line-height: 5vh;color: #939393">확인하다</div>
          </div>
      </van-pull-refresh>
    </div>
  </div>

</template>

<script>

export default {
  data() {
    return {
	userInfo:{},
      active: 2,
      isLoading: false,
      notice:{},
      notice2:{},
      notice1:{},
      loading: true,
      finished: false,
    };
  },
  methods: {
	showAlert(e,id) {
		this.$http({
		method: 'get',
		url: 'notice_del',
		data: { id: id }
		}).then(res=>{
			if(res.code === 200){
				alert('성공적으로 삭제되었습니다！');
				if(e===2){
					this.getNoticeList2(); 
				}else if(e===1){
					this.getNoticeList1();
				}else{
					this.getNoticeList();
				}
			}else{
				alert('시스템이 사용 중입니다. 나중에 다시 시도해 주세요!');
			}
		})

	},
    back(){
      return window.history.back();
    },
	getUserInfo(){
		this.$http({
		method: 'get',
		url: 'user_info'
		}).then(res=>{
		if(res.code === 200){
			this.userInfo = res.data;
			console.log(res);
		}else if(res.code ===401){
		this.$toast(res.msg);
		}
		})
	},
	addgo(id) {
		if (!localStorage.getItem('token')) {
		this.$router.push({ path: '/Login' });
		} else {
		this.$router.push({ path: `/NoticeInfo?id=${id}` });
		}
	},
    getNoticeList(){
      this.$http({
        method: 'get',
        url: 'sys_get_notice_all',
		data: { gid: 0 }
      }).then(res=>{
        console.log(res);
        this.notice = res.data
      })
    },
	getNoticeList2(){
      this.$http({
        method: 'get',
        url: 'sys_get_notice_list',
        data: { gid:2,uid:this.userInfo.id}
		}).then(res=>{
        console.log(res);
        this.notice2 = res.data
		})
	},
	getNoticeList1(){
      this.$http({
        method: 'get',
        url: 'sys_get_notice_all',
        data: { gid: 1 }
        }).then(res=>{
        console.log(res);
        this.notice1 = res.data
		})
	},
    onRefresh() {
      setTimeout(() => {
        //this.$toast("加载成功");
		this.getUserInfo();
        this.isLoading = false;
        this.getNoticeList();
		this.getNoticeList2();
		this.getNoticeList1();
      }, 500);
    },
	
  },
  created() {
	if(!localStorage.getItem('token')){
		this.$router.push({path:'/Login'})
	}
	this.onRefresh();  
	this.getUserInfo();
	this.getNoticeList();
	this.getNoticeList2();
	this.getNoticeList1();
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
::v-deep .van-pull-refresh__track .van-pull-refresh__head *{
  color: #000000;
  font-size: 35px;
}

::v-deep .van-loading__text {
  color: #000000;
  font-size: 35px;
}
.nav {
  display: flex;
  padding: 15px;

  .nav-item {
    font-size: 3.5vw;
    padding: 1.333vw 2vw;
    border-radius: 6.667vw;
    margin-right: 1.867vw;
    /* background: linear-gradient(90deg, #5b606c, #2b2b2b); */
    color: #fff;
    margin-bottom: 1.333vw;
	width: 33%;
	text-align: center;
    &.active {
      background: linear-gradient(#fde3ca, #e7b486);
      color: #c96708;
    }
  }
}
.nav-bar {
  background: linear-gradient(120deg, #212121, #313131);
}
.container .content {
  height: calc(100% - 20px);
  overflow: auto;
}
.container .content .listItem{
  margin-bottom: 20px;
  padding: 20px 20px 0;
  position: relative;
  color: #000;
  background-color: #fff;
  width: 90%;
  margin:auto;
  margin-bottom: 2vh;
border-radius: 0.5rem;
}
.container .content .listItem .listTitle{
  font-size: 1rem;
  color:#999;
  height: 5vh;
  border-bottom:1px solid #d9d9d9;
}
.container .content .listItem .listContent{
  border-bottom: 2px solid #f2f2f5;
  padding: 5px 0;
  font-size: 25px;
}
.container .content .listItem .listTime{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 70px;
}
.container .content .listItem .listTime .listTimeText {
  color: #656566;
  font-size: 30px;
}




</style>
