<template>
<div>
	<div class="diqu">
	<span @click="gotoMenu('/Home')"> <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAZCAYAAADJ9/UkAAAAAXNSR0IArs4c6QAAAY9JREFUSEu9ljlOA0EQRV9BSkrGNYhIIIUrkCICxL5JCAQCEbAvMkfgCIibsG8SOwkiJPpQVtsam7E9lqepdLrr/aru/jVGEyFpGOgACmb23cTW1KWWNYGkAjAS1g+a2XHWvbXWZYJLOgRGQ5InoNvM3qLDJR0AYwlwr5ndtgr2/XUrl7QPjAfQM+DgmzzAdeGS9oCJBLjPzK7zAteES9oFJgPoJVScKzgVLmkHmEqAveKrPCsu5ao4c0nbwHT4+Ao4+DIGuKJySVvAzH+By3BJm8BsAN8B/TErLrdd0iKwlmjtPfAJtMVqd8h7YpIega7IoHRvl+Tn7OddCjcRt872iIIEnBZvu6RlYCXAHoABMzuLCC+mLj81SQ53ER4f4ZlFFVD9zleBpYQA9/LzWB34M1gkrQMLAfgerPUihoDUqSZpA5iLLaDmSK2yWr/9fgS5Wm2jeZ4cq7kLaPgbleL53oFcplxDePCB6qHTY2Zuwy1FJngQkJzz82bmw6ilyAwPAoaATuDIzL5aIv9u/gF3aZIQA/773wAAAABJRU5ErkJggg=="> </span>	
		지역 선택
	</div>
	<div class="list_table">
		<!-- <van-grid :column-num="1" :gutter="10" class="list_table">
		<van-grid-item v-for="(item,index) in datalist" :key="index"> -->
		<li  @click="addgo(0)" >모두</li>
		<li v-for="(item, index) in datalist" :key="index" @click="addgo(item.id)" >{{item.name}}</li>
		<!-- </van-grid-item>
		</van-grid> -->
	</div>
</div>
</template>
<script>
export default {
  data() {
    return {
      active: 0,
      notice: "구하는......",
      banners: [{}],
      basicData: [],
      gameitem: [{}, {}, {}, {}],
      movielist_0: [{}, {}, {}, {}],
      movielist_1: [{}, {}, {}, {}, {}, {}, {}, {}],
      isLoading: false,
      movielistSwiperOption: {
        slidesPerView: 'auto',
        spaceBetween: 0,
        slidesPerGroup: 1,
      },
      bannerSwiperOption: {
        effect: 'coverflow',
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 'auto',
        speed: 800,
        autoplay: true,
        coverflowEffect: {
          rotate: 50,
          stretch: 10,
          depth: 100,
          modifier: 1,
          slideShadows: true
        }
      },
      datalist: [],
     
    };
  },
  
  methods: {
    addgo(id) {
      if (!localStorage.getItem('token')) {
        this.$router.push({ path: '/Login' });
      } else {
        this.$router.push({ path: `/home?id=${id}` });
      }
    },
    gotoMenu(router) {
      this.$router.replace(router)
    },
    toLottery(key, id) {
      if (!localStorage.getItem('token')) {
        this.$router.push({ path: '/Login' })
      } else {
        this.$router.push({ path: '/Lottery?key=' + key + "&id=" + id })
      }

    },
    toPlayVideo(id) {
      if (!localStorage.getItem('token')) {
        this.$router.push({ path: '/Login' })
      } else {
        this.$router.push({ path: '/PlayVideo?id=' + id })
      }
    },
    onRefresh() {
      setTimeout(() => {
        this.getBasicConfig();
        this.isLoading = false;
        this.$toast("새로고침 성공");
      }, 500);
    },
    getBasicConfig() {
      this.$http({
        method: 'get',
        url: 'sys_config'
      }).then(res => {
        this.basicData = res.data;

        this.getxuanfeilist();
      })

    },
    getxuanfeilist() {
      this.$http({
        method: 'get',
        url: 'citylist',
        data: { }
      }).then(res => {
        this.datalist = res.data;
      });
    },
    
    getBanner(data) {
      this.banners = data.banners;
    },
    gettip() {
      this.$http({
        method: 'get',
        url: 'sys_tip',
      }).then(res => {
        this.tip = res.data;
      });
    },
  },
  mounted() {

  },
  created() {
    this.getBasicConfig();
    this.gettip();
  }
}

</script>
<style>
.diqu{width: 100%;text-align: center;color: #fff;line-height: 8vh;font-size: 1.3rem;}
.diqu span{float: left;padding-left: 5%;position: absolute;left: 0;}
.diqu img{width: 70%;}
.list_table{width: 90%;height: 30vh;margin:auto;margin-left: 6%;}
.list_table li{list-style: none;float: left;width: 23%;text-align: center;height: 4vh;background-color: #EACC9B;margin-right: 2%;margin-bottom: 3%;line-height: 4vh;font-size: 0.91rem;font-weight: bold;border-radius: 0.1875rem;}
</style>