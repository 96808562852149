<template>
  <div class="convention-hall page" style="height: calc(100% - 200px)">
    <van-nav-bar class="nav-bar" title="공개인증" />
    <div class="convention-item rzlist">
        <div class="item2" @click="gotor('/Lottery?key=game2&id=12')">
          <div>
            <img src="http://a.xuan69.com/xuanfei/20231215/8aec844f3076e2f2878beb80c1f21106.jpg" style="margin-top: .5rem;"/>
            <div>도시 데이터1</div>
			<van-count-down :time="time" @finish="check()" style="font-size: 0.8125rem;color: #fa6200;margin-top: .5rem;margin-bottom: .5rem;" />
          </div>
        </div>
        <div class="item2" @click="gotor('/Lottery?key=game4&id=11')">
          <div>
            <img src="http://a.xuan69.com/xuanfei/20231215/5ee75a6e16a9c61c398e527a5c07e8b4.jpg" style="margin-top: .5rem;"/>
            <div>도시 데이터2</div>
			<van-count-down :time="time" @finish="check()" style="font-size: 0.8125rem;color: #fa6200;margin-top: .5rem;margin-bottom: .5rem;" />
          </div>
        </div>
        <div class="item2" @click="gotor('/Lottery?key=game1&id=10')">
          <div>
            <img src="http://a.xuan69.com/xuanfei/20231215/628d700502eedbbb0966eb29ac6eb1c9.jpg" style="margin-top: .5rem;"/>
            <div>도시 데이터1</div>
			<van-count-down :time="time" @finish="check()" style="font-size: 0.8125rem;color: #fa6200;margin-top: .5rem;margin-bottom: .5rem;" />
          </div>
        </div>
        <div class="item2" @click="gotor('/Lottery?key=game3&id=13')">
          <div>
            <img src="http://a.xuan69.com/xuanfei/20231215/229c91c937cf26bcbc90010144cfcdef.jpg" style="margin-top: .5rem;"/>
            <div>도시 데이터2</div>
			<van-count-down :time="time" @finish="check()" style="font-size: 0.8125rem;color: #fa6200;margin-top: .5rem;margin-bottom: .5rem;" />
          </div>
        </div>
		</div>
  </div>
</template>

<script>
import { Toast } from 'vant';
var time;
var count = 0;
export default {
  components: {  },
  data() {
    return {
      gameitem: [{}, {}, {}, {}],
      lotteryitem: [{}, {}, {}, {}],
      isLoading: false,
      active: 0,
      value: '',
      datalist: [],
      time:0,
      searchId: ''
    };
  },
  methods: {
    onSearch(val) {
      this.getxuanfeilist(val);
    },
    gotor(val){
        this.$router.push({ path: val });
    },
    addgo(id) {
      if (!localStorage.getItem('token')) {
        this.$router.push({ path: '/Login' });
      } else {
        this.$router.push({ path: `/profile?id=${id}` });
      }
    },
    getxuanfeilist() {
      this.$http({
        method: 'get',
        url: 'xuanfeilist',
        data: { id: this.$route.query.id || 1,uid:this.searchId }
      }).then(res => {
        // console.log(res.data)
        this.datalist = res.data;
      });
    },
    onRefresh() {
      setTimeout(() => {
        Toast('刷新成功');
        this.isLoading = false;
      }, 500);
    },
    toLottery(key, id) {
      if (!localStorage.getItem('token')) {
        this.$router.push({ path: '/Login' })
      } else {
        this.$router.push({ path: '/Lottery?key=' + key + "&id=" + id })
      }
    },
    getGameItem() {
      this.$http({
        method: 'get',
        url: 'lottery_list'
      }).then(res => {
        this.gameitem = res.data;
      })
    },
    onChange(index) {
      this.$http({
        method: 'get',
        data: { class: index },
        url: 'lottery_list'
      }).then(res => {
        this.gameitem = res.data;
      })
    },
    getLotteryItem() {
      this.$http({
        method: 'get',
        url: 'lottery_class'
      }).then(res => {
        this.lotteryitem = res.data;
      })
    },
	check() {
	if (!localStorage.getItem('token')) {
		this.$router.push({ path: '/Login' })
		} else {
		time = window.setInterval(() => {
			setTimeout(() => {
			//if (Object.keys(this.$route.query).length > 0) {
			//this.getUserInfo();
			this.getLotteryInfo();
			//this.getLotteryList();
			//}
	
			count++;
			if (count > 5) {
			clearInterval(time);
			count = 0;
			}
			}, 0)
		}, 4000)
		}
	},
	getLotteryInfo() {
		this.$http({
		method: 'get',
		data: { key: 'game2' },
		url: 'lottery_get_info'
		}).then(res => {
		if(res.code === 200) {
			// if(parseFloat(this.userInfo.money) < parseFloat(res.data.condition)) {
			// this.$toast("请联系管理员领取该任务!");
			// this.$router.push({ path: '/Home' })
			// return false;
			// }
			this.lottery = res.data;
			this.time = res.data.second * 1000;
	
			if(this.time / 1000 === 59) {
			//this.$toast("开奖成功，期号：" + this.lottery.now_expect);
		}
		if(res.data?.opencode) {
			this.shanzi_1 = "img/lottery/shaizi/" + res.data?.opencode[0] + ".png";
			this.shanzi_2 = "img/lottery/shaizi/" + res.data?.opencode[1] + ".png";
			this.shanzi_3 = "img/lottery/shaizi/" + res.data?.opencode[2] + ".png";
			this.sum = res.data.opencode[0] + res.data.opencode[1] + res.data.opencode[2];
			if(this.sum >= 11 && this.sum <= 18) {
			this.size = "큰";
			}else if(this.sum >= 3 && this.sum <= 10) {
			this.size = "작은";
			}
			if (this.sum % 2 === 0) {
			this.double = "쌍";
			} else {
			this.double = "하나";
			}
		}
		} else if (res.code === 401) {
		this.$toast(res.msg);
		}
	})
	
	},
	
  },
  
  
  destroyed() {
    clearInterval(time);
  },
  created() {
    // this.getGameItem();//获取首页游戏列表
    // this.getLotteryItem();
	this.check();
    this.getxuanfeilist();
  }
};
</script>

<style lang='less' scoped>
::v-deep .game_item_img {
    display: block;
    width: 100%;
    height: 60vw;
}	
/* 设置视频背景样式 */
  .background-video {
	/* position: fixed; */
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1; /* 确保视频在其他内容的后面 */
  }
  
  /* 设置页面内容样式 */
  .container {
	position: relative;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
  }

::v-deep .van-search__content {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    flex: 1;
    padding-left: 1.6vw;
    background-color: #5d5d5d;
    /* border-radius: 0.267vw; */
}
.van-search {
  background-color: #4d4d4d00;
}
::v-deep .van-field__left-icon .van-icon,
.van-field__right-icon .van-icon {
  display: block;
  font-size: 6.133vw;
  line-height: initial;
  margin-left: 4px;
  color: #bababa;
}
::v-deep .van-field__control {
    display: block;
    box-sizing: border-box;
    width: 100%;
    min-width: 0;
    margin: 0;
	padding: -1px !important;
    color: #323233;
    line-height: inherit;
    text-align: left;
    background-color: transparent;
    border: 0;
    resize: none;
    font-size: 4.2vw;
    margin-top: 2px;
}
::v-deep .van-grid-item__content--surround::after {
  border-width: 0px !important;
}

.van-grid-item__content::after {
  z-index: 1;
  border-width: 0px !important;
}

* {
  margin: 0;
  padding: 0;
}

.box {
  width: 100%;
  height: calc(100vh - 40px);
  background-color: rgb(141, 130, 130);
  position: relative;
}

.video {
  height: calc(100vh - 40px);
  background-size: 100% 100%;
  width: 100%;
  position: absolute;
  /* filter: blur(3px); */
  top: 0;
  left: 0;
}

.rig-name {
  background: linear-gradient(#fde3ca, #e7b486);
  color: #525252;
  width: 94%;
  margin: 1vw;
  flex: 1;
  border-radius: 0.667vw;
  text-align: center;
  font-size: 3vw;
  padding: 1vw;
}

.van-cell {
  width: 50%;
  font-size: 40px;
  text-align: center;
}

.button {
  border-radius: 10px;
  width: 50%;
  margin-top: 10px;
}

::v-deep .van-tab__pane {
  position: relative;
  height: 100%;
}

.live-box {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

::v-deep .van-tabs {
  height: 100%;
}

::v-deep .timibbs_zaixianzhibo {
  height: 100%;
}

::v-deep .van-tabs__wrap {
  height: auto;
}

::v-deep .van-tabs__line {
  bottom: 2vw;
  width: 7.333vw;
  height: 0.933vw;
  border-radius: 0;
  background-color: #ddb285;
}

::v-deep .van-tabs__nav {
  background-color: transparent;
}

::v-deep .van-tab {
  color: #ddb285;
  font-size: 4vw;
  line-height: inherit;
  padding: 30px 0;
}

::v-deep .van-tab--active {
  color: #ddb285;
  font-weight: 500;
}

.page {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
}

.nav-bar {
  background: linear-gradient(90deg, #7e5678, #e6c3a1);
  height: 100px;
}

.van-nav-bar {
  line-height: 50px;
}

::v-deep .van-nav-bar__title {
  max-width: 60%;
  margin: 0 auto;
  color: #ffffff;
  font-size: 35px;
}

::v-deep .van-nav-bar__content {
  height: 100px;
}

.van-sidebar {
  width: 180px;
}

.van-sidebar-item--select::before {
  left: 10px;
  height: 44%;
  background-color: #7e5678;
  border-radius: 5px;
  width: 10px;
}

.van-sidebar-item--select {
  color: #7e5678;
  font-size: 35px;
  text-align: center;
}

.van-sidebar-item {
  font-size: 35px;
  text-align: center;
  padding: 50px;
  background-color: #ffffff;
}

.van-sidebar-item--select,
.van-sidebar-item--select:active {
  background-color: #f2f2f5;
}

.convention-item {
  /* background: linear-gradient(120deg, #212121, #313131); */
  // padding-bottom: 180px;
  height: 100%;
}

.convention-hall {
  display: flex;
  flex-direction: column;
  bottom: 0;
  background: linear-gradient(120deg, #212121, #313131);
}

.convention-item .left {
  height: 100%;
  background-color: #fff;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.convention-item .right {
  height: 100%;
  flex: 1;
  background-color: #f2f2f5;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.convention-item .right .list-wrapper {
  padding: 20px 20px;
  min-height: 800px;
}

.convention-item .right .list-wrapper .game_item_img {
  /* width: 200px;
  height: 200px; */
}

.convention-item .right .list-wrapper span {
  margin-top: 10px;
  font-size: 30px;
  color: #000;
}

.convention-item .right .list-wrapper span:last-child {
  margin-top: 10px;
  font-size: 24px;
  color: #000;
}

.van-grid-item {
  padding: 10px;
  
}

::v-deep .van-grid-item__content--center {
  border-radius: 15px;
  padding: 0;
}


::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
  color: #000000;
  font-size: 35px;
  bottom: 90px;
  position: relative;
}

::v-deep .van-grid-item__content {
  background-color: black !important;
}
</style>
